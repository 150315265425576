export const WORDS = [
'körpe',
'acele',
'güneş',
'argon',
'kovan',
'birey',
'kazma',
'cebir',
'sıcak',
'dorse',
'döküm',
'memnu',
'tavla',
'sebil',
'yanak',
'ikbal',
'diyet',
'çıtır',
'yaslı',
'paket',
'kalın',
'silah',
'kulak',
'keten',
'kapak',
'neden',
'mayıs',
'cesur',
'fecir',
'ajans',
'aşağı',
'korna',
'kepçe',
'solcu',
'emare',
'ispir',
'kenet',
'koyma',
'hatta',
'çelim',
'didim',
'rehin',
'sinop',
'doğum',
'düğme',
'nimet',
'pıhtı',
'köfte',
'flört',
'sufle',
'yazgı',
'arşiv',
'hemze',
'çadır',
'tıbbi',
'kütlü',
'nezih',
'ilahi',
'gıyap',
'çorba',
'kaban',
'konak',
'ikili',
'nemli',
'yılkı',
'kırık',
'gasil',
'cenin',
'armut',
'şaşaa',
'kebir',
'denek',
'yasak',
'lüfer',
'ırkçı',
'otizm',
'aşlık',
'erinç',
'döküş',
'papak',
'kodes',
'söğüş',
'kıdem',
'sanık',
'imbat',
'bucak',
'boyut',
'ıslah',
'büzgü',
'matem',
'antik',
'çömez',
'anime',
'kabak',
'hacet',
'buşon',
'hesap',
'vefat',
'çöküş',
'sönüş',
'yıkım',
'çelme',
'içten',
'köçek',
'hoppa',
'teyze',
'sırık',
'bıçak',
'kazan',
'piyaz',
'kazak',
'motor',
'kolaj',
'külçe',
'banka',
'kapiş',
'içsel',
'kumar',
'yarık',
'topuz',
'basit',
'tablo',
'satma',
'çehre',
'köpek',
'maden',
'melek',
'bacak',
'koşul',
'bagaj',
'mümin',
'libre',
'magma',
'kavkı',
'katre',
'efekt',
'oğlan',
'tıkaç',
'şişik',
'sayfa',
'kelep',
'genel',
'torba',
'anlam',
'mafya',
'direk',
'müzik',
'sucuk',
'etmen',
'yerel',
'emtia',
'umumi',
'herif',
'ceviz',
'branş',
'cisim',
'dizgi',
'çalgı',
'nefis',
'riyal',
'güllü',
'kiril',
'turbo',
'deizm',
'yalak',
'çiçek',
'külli',
'vahiy',
'aktif',
'rimel',
'tümör',
'patik',
'ucube',
'örülü',
'yokuş',
'marul',
'sıfır',
'dübel',
'taocu',
'salat',
'yekta',
'devir',
'pilav',
'melun',
'kavak',
'resul',
'algın',
'amber',
'gazel',
'semiz',
'çeşit',
'mekan',
'tanık',
'şehit',
'kazık',
'doyum',
'yüzme',
'fıtri',
'eşraf',
'şebek',
'kalça',
'keven',
'mürit',
'andaç',
'takip',
'siyer',
'laçin',
'soluk',
'zevce',
'dinme',
'vokal',
'batıl',
'nüfuz',
'seans',
'ambar',
'salgı',
'itlik',
'kumru',
'dizek',
'döviz',
'hasar',
'yarar',
'ihsan',
'ulufe',
'dobra',
'çekik',
'çakma',
'metro',
'pigme',
'basur',
'tayfa',
'ekmek',
'tıpkı',
'vakıf',
'berat',
'safra',
'evrak',
'küfür',
'ferah',
'hindu',
'horoz',
'çığır',
'biber',
'funda',
'mebus',
'hatip',
'dahil',
'varil',
'kakül',
'kalan',
'bütçe',
'tirit',
'entel',
'sapık',
'saygı',
'meşin',
'özgür',
'açlık',
'bamya',
'ranza',
'bakım',
'legal',
'erkan',
'elips',
'radyo',
'tövbe',
'matbu',
'ishal',
'hudut',
'metan',
'çizgi',
'vekil',
'memba',
'gafil',
'dadaş',
'mevzi',
'çabuk',
'hukuk',
'yetki',
'telef',
'cihan',
'duruş',
'bakır',
'diyez',
'sanal',
'karar',
'arife',
'böcek',
'dumur',
'enkaz',
'bölüm',
'pasör',
'incir',
'tekme',
'ayyuk',
'buğra',
'utanç',
'emzik',
'basın',
'kefal',
'çavuş',
'ardıç',
'kabin',
'siğil',
'kaput',
'dübeş',
'yalpa',
'sahte',
'hokka',
'çelik',
'pinti',
'zemin',
'sarık',
'tütsü',
'havan',
'eviye',
'kokoş',
'sütlü',
'döşek',
'tuzla',
'diğer',
'uyruk',
'çello',
'güdüm',
'serap',
'inanç',
'düzen',
'başak',
'sauna',
'avare',
'davar',
'kalay',
'pelit',
'tuzcu',
'ceren',
'yavan',
'muzip',
'akort',
'istif',
'evrim',
'ölmüş',
'kopya',
'sahaf',
'cadde',
'tokuz',
'alaca',
'kahve',
'zehir',
'sıhhi',
'başçı',
'bahçe',
'önder',
'sonda',
'vapur',
'yemin',
'şubat',
'imsak',
'büluğ',
'statü',
'iplik',
'selim',
'desen',
'kesme',
'topuk',
'işlev',
'güdük',
'kargo',
'barem',
'topaç',
'hırbo',
'aracı',
'hapşu',
'ancak',
'katır',
'zalim',
'ispat',
'tuğra',
'mısra',
'sinsi',
'ardıl',
'somon',
'eşarp',
'haram',
'etsiz',
'ummak',
'zorla',
'beter',
'domuz',
'mühür',
'ihram',
'sizce',
'iyice',
'giriş',
'torna',
'diriğ',
'delta',
'barış',
'duhul',
'düğüm',
'golcü',
'aidat',
'mutlu',
'süzük',
'hazcı',
'kısış',
'fitin',
'şuara',
'kollu',
'şerpa',
'şilin',
'honaz',
'biraz',
'bindi',
'viral',
'laçın',
'robot',
'savak',
'bekçi',
'sidik',
'kisve',
'pusma',
'sorum',
'tariz',
'sekil',
'dipli',
'hizan',
'gıdık',
'saten',
'çaylı',
'etkin',
'kesat',
'maket',
'uzama',
'tavlı',
'madde',
'serik',
'medar',
'kolan',
'peyda',
'mabut',
'tulup',
'kaşif',
'güruh',
'ürkme',
'uygun',
'oyalı',
'üçlük',
'pulcu',
'küflü',
'cimri',
'ılıca',
'yuvar',
'haklı',
'şimal',
'öncül',
'yufka',
'kasım',
'yavuz',
'kurye',
'kapan',
'sedir',
'araba',
'hakan',
'iskoç',
'sekiz',
'tuzlu',
'buhar',
'petek',
'ilahi',
'kukla',
'mobil',
'torak',
'hücre',
'melce',
'çamur',
'saçış',
'gayur',
'türbe',
'kumaş',
'basen',
'abiye',
'mehaz',
'aktar',
'rasıt',
'tutku',
'korse',
'sözce',
'davul',
'teker',
'talip',
'tabur',
'kırma',
'beste',
'balat',
'rögar',
'endam',
'evlat',
'etçil',
'payda',
'kafir',
'içeri',
'üstel',
'cürüm',
'yorum',
'fitre',
'debbe',
'volta',
'rızık',
'nisan',
'fasık',
'sargı',
'irice',
'yağcı',
'yatır',
'masöz',
'iğdiş',
'hısım',
'akıcı',
'ileri',
'refah',
'necip',
'valiz',
'işlek',
'acılı',
'kadir',
'kışla',
'fiske',
'siyah',
'dolar',
'sakal',
'soğan',
'lisan',
'yünlü',
'mizan',
'tesir',
'ücret',
'garaj',
'ilkel',
'punto',
'zeval',
'hamam',
'tebaa',
'ulama',
'totem',
'çıkar',
'külot',
'zorlu',
'namlı',
'buzul',
'peder',
'geniz',
'isevi',
'müjde',
'gurup',
'bağış',
'anemi',
'mazot',
'casus',
'onluk',
'kargı',
'ahenk',
'meğer',
'kuşçu',
'husus',
'vakfe',
'içkin',
'ninni',
'meyan',
'ekili',
'haspa',
'balon',
'fiili',
'şöyle',
'kulüp',
'çükür',
'çalım',
'liste',
'vakar',
'kesin',
'dişli',
'serum',
'tutam',
'tiran',
'tesis',
'kaçık',
'pembe',
'hızla',
'papel',
'çıkın',
'derbi',
'sesli',
'anane',
'nüsha',
'üzgün',
'hatun',
'gerek',
'dolay',
'huzur',
'sürgü',
'sığma',
'hicri',
'dicle',
'karşı',
'semen',
'bazal',
'yığma',
'bursa',
'tonla',
'enzim',
'kanca',
'değil',
'kırım',
'düşük',
'kimya',
'basma',
'bazen',
'uygur',
'kahır',
'azılı',
'kıtır',
'rekat',
'yarım',
'pürüz',
'figür',
'cizye',
'komik',
'keser',
'keban',
'kinci',
'sipsi',
'çizim',
'frigo',
'dönüt',
'zabıt',
'haşin',
'yakıt',
'hayal',
'helva',
'matah',
'artçı',
'bebek',
'adeta',
'dönel',
'erbaş',
'eflak',
'vecih',
'kitle',
'sıkım',
'şayet',
'gazap',
'polat',
'camia',
'ariza',
'testi',
'bahir',
'giyiş',
'komut',
'takat',
'tuval',
'şeker',
'çerçi',
'bayma',
'sadet',
'korku',
'hazar',
'dönüm',
'ladik',
'nağme',
'salık',
'cinli',
'beşiz',
'koşam',
'zerre',
'pikap',
'tezat',
'nahır',
'şedde',
'ahmak',
'ırmak',
'irfan',
'kumsu',
'sevir',
'bando',
'baget',
'lipit',
'medya',
'sırcı',
'toycu',
'kibir',
'romen',
'kaplı',
'somya',
'iffet',
'kanal',
'rakım',
'pabuç',
'maraz',
'ölçer',
'kurma',
'yamçı',
'sabuh',
'nicel',
'galoş',
'dizel',
'glase',
'sürre',
'koyak',
'pesek',
'spazm',
'likör',
'halim',
'cevaz',
'hüzme',
'kibar',
'infaz',
'haşiv',
'sunak',
'sırlı',
'oylum',
'doygu',
'takti',
'yenme',
'ifrat',
'molas',
'şıpka',
'kramp',
'godoş',
'çukur',
'hindi',
'yassı',
'yetme',
'serme',
'melas',
'dizim',
'titan',
'tözel',
'dallı',
'sapış',
'serçe',
'datça',
'şekel',
'abone',
'dalsı',
'rosto',
'bidon',
'yatık',
'ahval',
'sübye',
'himen',
'tetir',
'bayan',
'nutuk',
'içsiz',
'tümey',
'rumba',
'fırça',
'yobaz',
'kütör',
'kısıt',
'halen',
'süven',
'beşer',
'falan',
'pagan',
'sıyga',
'ezani',
'hacir',
'fetüs',
'faraş',
'kiriş',
'tuhaf',
'paslı',
'kadeh',
'demeç',
'kupon',
'seher',
'tahra',
'gayrı',
'veriş',
'teşri',
'çelgi',
'ıslık',
'şalak',
'evdeş',
'kıray',
'semer',
'havva',
'emaye',
'ocuma',
'tamir',
'resim',
'fayda',
'banma',
'çaput',
'malik',
'tapon',
'tugay',
'sıska',
'manej',
'hızır',
'nefes',
'cezve',
'cihet',
'bulut',
'şifon',
'dosya',
'ikame',
'varış',
'geçek',
'sıçma',
'sevim',
'atlet',
'yutak',
'unsur',
'martı',
'idare',
'reşit',
'ovmak',
'çavun',
'salvo',
'ebcet',
'lirik',
'tadil',
'yırık',
'arıza',
'ülker',
'tohum',
'ihlal',
'elmas',
'nitel',
'cücük',
'hamur',
'değme',
'kendi',
'lamba',
'kobay',
'kötek',
'batar',
'şepit',
'çubuk',
'kaslı',
'kolon',
'savcı',
'sayış',
'vacip',
'kırat',
'matuh',
'temel',
'zaten',
'tüvit',
'yanay',
'ponza',
'leğen',
'düvel',
'noter',
'saban',
'kabus',
'elbet',
'ötmek',
'vulva',
'telsi',
'gulet',
'jikle',
'yatım',
'yenik',
'miraç',
'ortak',
'atılı',
'kumla',
'harir',
'örtme',
'hamil',
'kelik',
'yolcu',
'suruç',
'cinsi',
'fanta',
'karni',
'orgcu',
'çalar',
'rakun',
'iğfal',
'çarka',
'galip',
'fondü',
'polip',
'itham',
'seman',
'pampa',
'kalım',
'madem',
'çalış',
'dalış',
'tayin',
'kopal',
'ivesi',
'sinüs',
'kutnu',
'tekke',
'yılma',
'buruk',
'giray',
'ümera',
'saçma',
'sosis',
'pafta',
'zanka',
'afife',
'söküm',
'forum',
'tarif',
'erdek',
'öğrek',
'zinde',
'yemek',
'soket',
'helak',
'milat',
'aktör',
'çöpçü',
'oosit',
'yancı',
'sukut',
'olası',
'namaz',
'yakın',
'ajite',
'salcı',
'kozak',
'yengi',
'gedik',
'evrik',
'skala',
'namlu',
'zülüf',
'kolit',
'rüküş',
'mayna',
'çörtü',
'fisto',
'kayıt',
'yosma',
'lavaş',
'finiş',
'vakit',
'sakaf',
'sikme',
'tünel',
'frenk',
'gayri',
'meles',
'sanrı',
'kaşar',
'çuval',
'bölüt',
'sinik',
'resmi',
'stres',
'süvme',
'şayak',
'şerir',
'turna',
'rulet',
'koyut',
'silki',
'alçak',
'aşama',
'vahit',
'iflah',
'torum',
'konuş',
'eskiz',
'girdi',
'hamse',
'lahza',
'bezen',
'revaç',
'atmak',
'miyav',
'katkı',
'yerme',
'coşum',
'sadak',
'kupür',
'yeşil',
'milli',
'terek',
'şüphe',
'güğüm',
'zıbın',
'yemiş',
'kürek',
'plato',
'içyüz',
'yivli',
'lasta',
'aşari',
'okume',
'vurgu',
'gadir',
'dasit',
'gamze',
'filet',
'şapel',
'vaşak',
'ıtrah',
'burun',
'susuş',
'nehiy',
'uygar',
'tasni',
'telek',
'avans',
'baron',
'sefil',
'alaka',
'vatka',
'kabuk',
'aspur',
'makat',
'memur',
'mukus',
'günlü',
'salça',
'raket',
'ileti',
'siren',
'zümre',
'coşuş',
'takla',
'kuyum',
'aslık',
'yayın',
'tutya',
'mumcu',
'dürtü',
'heyet',
'çakın',
'gelin',
'seyek',
'ferda',
'mujik',
'taşlı',
'kayşa',
'taşma',
'emlak',
'sakıt',
'arpçı',
'evaze',
'helen',
'itmam',
'lazut',
'kayaç',
'kütle',
'cirit',
'ölmek',
'derme',
'eylem',
'anlık',
'idrak',
'çinli',
'aşılı',
'iğdir',
'sefir',
'balya',
'neyse',
'lokal',
'sezgi',
'dolak',
'şinto',
'hayıt',
'ıssız',
'varis',
'canik',
'şefik',
'meful',
'kopça',
'yanık',
'gümeç',
'nısıf',
'pelin',
'apiko',
'etlik',
'neler',
'erbap',
'ördek',
'zühre',
'gümüş',
'yazma',
'kurya',
'tenge',
'malca',
'hımış',
'maruz',
'itici',
'konsa',
'demci',
'edebi',
'sadık',
'havlı',
'bugün',
'zırva',
'sahra',
'ralli',
'freze',
'mıgır',
'bilme',
'potin',
'ikici',
'nekes',
'uçarı',
'latin',
'uzluk',
'gödeş',
'izlek',
'uzman',
'fonda',
'hazan',
'günah',
'yutma',
'draje',
'kahil',
'kesel',
'nahoş',
'skink',
'fevri',
'nonoş',
'selam',
'tekli',
'karye',
'fujer',
'angıç',
'gürsu',
'kiliz',
'estet',
'lamel',
'döngü',
'dergi',
'ergin',
'zarif',
'fanya',
'gazoz',
'dikiz',
'bakla',
'zevat',
'evren',
'ibdai',
'sonuç',
'dünür',
'otluk',
'füsun',
'niyet',
'umacı',
'nalan',
'dılak',
'idman',
'akide',
'kaçta',
'streç',
'muhit',
'polim',
'çanta',
'canan',
'yitme',
'pişim',
'tutaç',
'debil',
'şahap',
'semih',
'sence',
'gönül',
'kumul',
'turno',
'üzlük',
'kalbi',
'tekst',
'ikmal',
'ütülü',
'baliğ',
'yılan',
'bilgi',
'türkü',
'tarih',
'istek',
'yamuk',
'kıble',
'dozaj',
'bilge',
'sulak',
'fizik',
'dövüş',
'kapuz',
'övmek',
'epeyi',
'kotan',
'satır',
'kepir',
'çalma',
'işkil',
'tanıt',
'ekler',
'plaza',
'kagir',
'galop',
'türlü',
'torun',
'sunum',
'uçkun',
'racon',
'cahil',
'keşen',
'gabro',
'tomak',
'lavuk',
'kucak',
'lanse',
'geyik',
'çaker',
'toyca',
'perma',
'kaşlı',
'sazak',
'yumak',
'çörek',
'revan',
'fikir',
'yağır',
'çevre',
'ödlek',
'kızma',
'oktan',
'palto',
'misis',
'koşma',
'japon',
'rasat',
'rüsum',
'başta',
'vizör',
'vakum',
'kepme',
'dövme',
'hokey',
'batik',
'tutuş',
'patoz',
'aleyh',
'kasti',
'söven',
'makus',
'salta',
'vişne',
'vonoz',
'giyme',
'kafes',
'sibop',
'ilbay',
'karış',
'mesih',
'halat',
'tıraş',
'elden',
'şümul',
'sızma',
'muylu',
'vukuf',
'beniz',
'aport',
'çözüm',
'havuç',
'üstat',
'kaşık',
'irade',
'afyon',
'ekose',
'efriz',
'bitik',
'yağsı',
'biçem',
'uğrak',
'küşne',
'celil',
'irsen',
'nefiy',
'doyuş',
'esmek',
'temek',
'evkaf',
'tuğlu',
'sitem',
'falcı',
'yanaz',
'manga',
'yaşıt',
'merci',
'pufla',
'ozuga',
'kösem',
'gülük',
'cevap',
'tuluk',
'alevi',
'arabi',
'peruk',
'çivit',
'sekme',
'mizaç',
'çıpır',
'haybe',
'ayraç',
'sıkça',
'denet',
'henüz',
'çince',
'imroz',
'komot',
'niçin',
'keyfi',
'teati',
'fitçi',
'yıkma',
'klima',
'demek',
'ötürü',
'kesek',
'gömme',
'kavuz',
'essah',
'ithal',
'eşmek',
'tiraj',
'vizon',
'erkli',
'sayha',
'yiğit',
'zombi',
'yankı',
'turfa',
'şinik',
'tekçi',
'misal',
'şikar',
'bende',
'sarış',
'rekiz',
'yığın',
'tırak',
'nazır',
'arena',
'kadro',
'düzey',
'konuk',
'geriz',
'şapçı',
'tahta',
'savma',
'nanik',
'herek',
'hasat',
'recep',
'törel',
'yakma',
'eğlek',
'nalça',
'kombi',
'uşkun',
'gider',
'niyaz',
'buluş',
'eşlek',
'taçlı',
'cepçi',
'çeyiz',
'özbek',
'kurgu',
'devri',
'kasem',
'çakım',
'gizil',
'orman',
'fagot',
'ataşe',
'frank',
'moğol',
'çözme',
'sürek',
'hazır',
'doğal',
'sazcı',
'pengö',
'onsuz',
'sokur',
'gelen',
'puset',
'geçim',
'garoz',
'ferma',
'konik',
'burgu',
'köksü',
'erciş',
'dizem',
'tayın',
'artma',
'cezbe',
'yakin',
'kolye',
'daday',
'tekel',
'nikel',
'merak',
'balık',
'rahim',
'divan',
'damga',
'büzme',
'tente',
'haşıl',
'mavra',
'nikah',
'hafız',
'baret',
'hidiv',
'samba',
'orlon',
'nesim',
'vazıh',
'sürat',
'talim',
'mucuk',
'gavur',
'sehiv',
'yaşam',
'katma',
'külte',
'yanma',
'tonlu',
'savca',
'hapaz',
'afili',
'moren',
'yayan',
'yelin',
'ikram',
'sızış',
'kesif',
'öteki',
'rahip',
'bozuk',
'haraç',
'mesul',
'kobra',
'radde',
'lifli',
'harem',
'garip',
'liger',
'komşu',
'özalp',
'şahıs',
'humor',
'tayip',
'kıvam',
'ibrik',
'amade',
'hıyar',
'atama',
'tekir',
'muska',
'şehir',
'lafız',
'kısmi',
'koşin',
'itlaf',
'masnu',
'belki',
'önlük',
'salur',
'halef',
'folyo',
'cibin',
'uluma',
'daraş',
'örgün',
'durak',
'sigar',
'sölom',
'horst',
'zağar',
'muhat',
'haydi',
'yapış',
'tatil',
'öksüz',
'sivil',
'mırra',
'aşmak',
'gülme',
'hakas',
'gözer',
'yetim',
'amele',
'revir',
'sakız',
'çayan',
'tamah',
'ızgın',
'banyo',
'lavaj',
'utmak',
'kozan',
'sübut',
'evgin',
'melik',
'limbo',
'insan',
'taşıt',
'harbe',
'mutaf',
'kızak',
'pikaj',
'porte',
'celse',
'imale',
'havza',
'motif',
'ermiş',
'zifir',
'moloz',
'mecmu',
'hitan',
'şiran',
'elçim',
'palaz',
'klapa',
'sebat',
'kışır',
'güven',
'mürai',
'düden',
'mesmu',
'üçler',
'sihir',
'erdem',
'havra',
'degaj',
'özeme',
'çotra',
'sevme',
'tüpçü',
'yakış',
'cıvık',
'tonoz',
'biliş',
'ojeli',
'kerki',
'merih',
'nakip',
'kafur',
'geççe',
'delgi',
'recim',
'kerti',
'tümür',
'kadem',
'idame',
'haluk',
'takık',
'giren',
'deşik',
'ferli',
'fesih',
'karun',
'pudra',
'tasım',
'salya',
'şaryo',
'emval',
'tümen',
'çisen',
'herik',
'tetik',
'uçkur',
'reşme',
'kızıl',
'şaban',
'kösnü',
'parti',
'kutur',
'kanto',
'tuğla',
'sutlu',
'gözlü',
'albay',
'diyar',
'amigo',
'pruva',
'sofra',
'hassa',
'zayıf',
'çeşme',
'göden',
'yanlı',
'killi',
'talil',
'kalma',
'trake',
'tasdi',
'ruhen',
'turta',
'elcik',
'şölen',
'şişek',
'yiyiş',
'resen',
'saman',
'dokuz',
'enayi',
'çökel',
'harap',
'doğuş',
'aksan',
'erzin',
'şilem',
'salep',
'kasma',
'duvak',
'hisli',
'şoför',
'yokçu',
'taşsı',
'göbek',
'ilmek',
'takım',
'tevsi',
'filan',
'moher',
'gulaş',
'falez',
'havsa',
'göbüt',
'piket',
'cenap',
'saçlı',
'kürar',
'kelem',
'yeygi',
'segah',
'izole',
'gerze',
'koyar',
'ciğer',
'ihbar',
'mayın',
'midye',
'narin',
'gidon',
'asabi',
'tromp',
'safer',
'sanış',
'çişik',
'yüzüş',
'bariz',
'durma',
'soyuş',
'stent',
'korte',
'eyvah',
'cebin',
'susak',
'lipom',
'aksam',
'alarm',
'gizli',
'yediz',
'hışır',
'ödünç',
'tekin',
'örücü',
'kuzey',
'hüyük',
'total',
'dilsi',
'kalış',
'bomba',
'gayet',
'içlik',
'pense',
'mader',
'hiciv',
'gövel',
'etraf',
'keşap',
'yalın',
'cemil',
'kabız',
'ibate',
'taban',
'sönük',
'andıç',
'öğlen',
'hulul',
'ayırt',
'daimi',
'hamız',
'germe',
'ceset',
'kutup',
'çancı',
'rekor',
'çilek',
'tevki',
'tomar',
'ihraç',
'rütbe',
'grado',
'moral',
'ihsas',
'işeme',
'şayan',
'tezli',
'ampul',
'kıyye',
'hekim',
'hülle',
'fenik',
'kesit',
'hutbe',
'yaver',
'çiten',
'nişan',
'sunuş',
'konut',
'karne',
'latif',
'fuaye',
'eglog',
'menus',
'bafra',
'malcı',
'badem',
'çizme',
'izabe',
'üreme',
'safça',
'ruble',
'ulema',
'haliç',
'kınık',
'tanrı',
'fosil',
'kitap',
'alıcı',
'tozlu',
'kofra',
'argıt',
'zigon',
'pusat',
'ündeş',
'çokal',
'litre',
'toplu',
'kaçış',
'sıkma',
'eroin',
'nispi',
'lando',
'nesne',
'dekan',
'geziş',
'sökel',
'taşım',
'ünite',
'çizik',
'halet',
'kömür',
'atıcı',
'gayda',
'esame',
'eğrim',
'mançu',
'azade',
'görüş',
'keşif',
'salak',
'seçiş',
'sorti',
'öreke',
'yetke',
'hilat',
'kebze',
'fariğ',
'nakış',
'gönye',
'felah',
'ipçik',
'cemal',
'kekre',
'teyel',
'efkar',
'çıyan',
'yiyim',
'şaful',
'şaire',
'kükre',
'hilaf',
'ahize',
'ilenç',
'porto',
'ingin',
'cedre',
'fıkıh',
'bayat',
'çapla',
'yergi',
'soruş',
'yaylı',
'cehri',
'buçuk',
'kopma',
'villa',
'tabut',
'lerze',
'tehir',
'şanlı',
'yalız',
'taksi',
'pilli',
'eşkal',
'nazik',
'nogay',
'masat',
'kanka',
'adsız',
'koşuk',
'damla',
'derik',
'eşkin',
'yutum',
'kısas',
'iksir',
'tilki',
'yaygı',
'efsun',
'pelte',
'matla',
'natuk',
'modem',
'ritim',
'kayın',
'mısır',
'üğrüm',
'çakar',
'virüs',
'sırma',
'çapul',
'kuşça',
'ister',
'sperm',
'koton',
'çitme',
'tekil',
'dinen',
'titiz',
'hempa',
'otçul',
'büyük',
'öznel',
'göçük',
'ofans',
'kesim',
'safir',
'çocuk',
'yangı',
'dualı',
'zıpır',
'düzme',
'sprey',
'gereç',
'habbe',
'tacik',
'lağım',
'pazar',
'imleç',
'beyan',
'nafiz',
'nabız',
'köpük',
'raspa',
'batık',
'hozat',
'kifaf',
'gusül',
'ismen',
'pompa',
'dolam',
'turan',
'fanus',
'cezai',
'avdet',
'yüzey',
'metin',
'mimik',
'talas',
'kiniş',
'kasır',
'meleş',
'şunca',
'kuver',
'yelme',
'yayış',
'emcek',
'rotor',
'kübik',
'diken',
'katık',
'teali',
'irite',
'tuğcu',
'yafta',
'moruk',
'yansı',
'pumba',
'çağla',
'tokaç',
'kuduz',
'ıslak',
'fulya',
'çalak',
'gebeş',
'sicil',
'polis',
'ilgeç',
'sivri',
'iblis',
'eskil',
'kayak',
'avunç',
'tepik',
'küçük',
'çomak',
'veciz',
'medih',
'ortoz',
'fasıl',
'masal',
'külah',
'sinme',
'fatih',
'cicim',
'yumuk',
'mehil',
'kütin',
'koşum',
'çuvaş',
'mehdi',
'fıtık',
'saray',
'tadat',
'gelir',
'tonga',
'şelek',
'cirim',
'lojik',
'dozer',
'sayın',
'mahfi',
'yürek',
'süzgü',
'diyot',
'forma',
'kanık',
'kurul',
'olmuş',
'uknum',
'cünüp',
'düşes',
'şerif',
'fetva',
'temiz',
'kopuk',
'sason',
'kokot',
'genom',
'visal',
'tosun',
'sınav',
'gözcü',
'bütan',
'sıçan',
'kayış',
'oralı',
'hakem',
'teğet',
'defne',
'sirto',
'sübek',
'kupes',
'itmek',
'terör',
'çeçen',
'bıdık',
'keman',
'çiğil',
'pütür',
'şuhut',
'kaniş',
'pelüş',
'bozum',
'imkan',
'köylü',
'hapçı',
'kakıç',
'canlı',
'yedek',
'egzoz',
'gocuk',
'yayım',
'çedik',
'sahih',
'kıyık',
'kurak',
'ilmik',
'kasnı',
'lahit',
'kuman',
'tifüs',
'vuruş',
'fodul',
'havlu',
'mesai',
'durum',
'etsel',
'gözgü',
'posta',
'mahal',
'yelve',
'uyarı',
'engel',
'erbaa',
'balcı',
'ümmet',
'video',
'sınma',
'rükün',
'kadük',
'trans',
'çebiç',
'bitki',
'lokma',
'azize',
'uçucu',
'eşref',
'peron',
'yapıt',
'eslaf',
'muhil',
'hazin',
'düşüt',
'otlak',
'ilham',
'kesre',
'tutma',
'toput',
'likit',
'vatoz',
'somak',
'facia',
'uçman',
'menfi',
'mekik',
'koket',
'etnik',
'metal',
'gövem',
'milis',
'sümek',
'nesil',
'ditme',
'tecil',
'sathi',
'kotra',
'hamiş',
'makta',
'balta',
'sülük',
'lazer',
'zirve',
'fidye',
'isyan',
'kinik',
'mitos',
'gedme',
'ifade',
'takoz',
'çayır',
'hazne',
'kıyas',
'göçer',
'hamut',
'zabit',
'fahte',
'kaset',
'kavaf',
'yonga',
'salip',
'esbap',
'çopra',
'şaplı',
'tepki',
'setir',
'yamaç',
'yüzde',
'iştah',
'hancı',
'sinle',
'ofris',
'maruf',
'teşne',
'nahif',
'evcik',
'bıçık',
'varma',
'inkar',
'keşki',
'mumlu',
'resif',
'eosen',
'denim',
'çepez',
'çekçe',
'artık',
'höyük',
'nazil',
'beher',
'dolap',
'hilal',
'orcik',
'loşça',
'lakap',
'yeğin',
'kanun',
'kefil',
'antet',
'tekit',
'fitne',
'matuf',
'başlı',
'tranş',
'saçık',
'hasta',
'mabet',
'kesik',
'taraş',
'çokça',
'kızış',
'semah',
'klips',
'çakır',
'nevir',
'meşru',
'müftü',
'lemis',
'boyna',
'zirai',
'kolej',
'dönük',
'koala',
'çimen',
'eklem',
'pelür',
'oluru',
'simya',
'azgın',
'nadir',
'reyon',
'sarma',
'mantı',
'sürüş',
'varta',
'haile',
'fakül',
'nakdi',
'buzlu',
'bulgu',
'sinek',
'yalaz',
'lağıv',
'dutçu',
'sanat',
'pullu',
'gülcü',
'zenne',
'ketal',
'çapak',
'ümran',
'rötar',
'harta',
'zuhur',
'sözde',
'inmek',
'randa',
'ölmez',
'angut',
'mikro',
'saloz',
'lünet',
'yönlü',
'islim',
'zıpka',
'tapir',
'çupra',
'helal',
'yüzük',
'münşi',
'kuran',
'çiğin',
'ermek',
'çalap',
'nemçe',
'çeşni',
'klişe',
'pipet',
'bozca',
'terbi',
'yeğen',
'tapan',
'ledün',
'praya',
'eseme',
'ezgiç',
'sabah',
'dığan',
'kader',
'kılma',
'çaycı',
'bobin',
'rahat',
'anten',
'bunak',
'teşci',
'vasat',
'torik',
'kalıp',
'apacı',
'doyma',
'perva',
'ilahe',
'ıstar',
'masum',
'kemah',
'öpmek',
'çeker',
'afgan',
'sokra',
'delil',
'edalı',
'barut',
'takim',
'kimüs',
'oleik',
'bambu',
'birim',
'gevaş',
'yezit',
'dikeç',
'arşın',
'kokak',
'kalas',
'hayat',
'vezir',
'taşıl',
'pasif',
'şurup',
'güzün',
'kutsi',
'kamil',
'tozma',
'makam',
'gaile',
'pünez',
'erkeç',
'stant',
'esham',
'verev',
'kireç',
'gerim',
'pırpı',
'ervah',
'tımar',
'tango',
'şarkı',
'otama',
'drama',
'zifin',
'hayli',
'yağlı',
'ibzal',
'bitap',
'örtük',
'hitam',
'tuzsu',
'oberj',
'tufan',
'akşın',
'tarla',
'kahir',
'küşüm',
'dökme',
'süreç',
'kalfa',
'kesir',
'masör',
'ziyan',
'tosya',
'salsa',
'rakip',
'pesüs',
'celal',
'yazıt',
'akson',
'hedef',
'sunma',
'salon',
'manda',
'çünkü',
'efdal',
'kımız',
'hayır',
'banal',
'seyit',
'iznik',
'geçiş',
'ürkek',
'içrek',
'erlik',
'gamlı',
'kravl',
'hamle',
'kilüs',
'tavan',
'çoklu',
'işsiz',
'muğla',
'lökoz',
'kıygı',
'kasıt',
'güdül',
'firak',
'tamam',
'ıştır',
'mavna',
'döner',
'kavun',
'didar',
'ongen',
'kökçü',
'tuyuğ',
'kanlı',
'dakik',
'madun',
'kefne',
'turba',
'manto',
'sapan',
'incil',
'meyus',
'lodos',
'ifrağ',
'saplı',
'yuvak',
'heder',
'meram',
'ameli',
'hafta',
'kümes',
'pasak',
'turşu',
'yarın',
'mutat',
'çolak',
'gücün',
'kaçma',
'badik',
'yarma',
'lügol',
'oyacı',
'esasi',
'çekel',
'yonma',
'gırla',
'şakak',
'çelen',
'harbi',
'hurda',
'mecal',
'üzücü',
'dolum',
'sekel',
'kerem',
'tecim',
'tavus',
'sabur',
'benek',
'kiraz',
'gauss',
'vebal',
'yayla',
'kolcu',
'yüzer',
'senit',
'onlar',
'gollü',
'sövme',
'donör',
'kakao',
'çerez',
'yazır',
'hamal',
'makro',
'çecik',
'korza',
'kumuk',
'üçkat',
'örnek',
'masaj',
'kayan',
'dinek',
'inşat',
'aşkın',
'viraj',
'çigan',
'talik',
'kubbe',
'saraç',
'setik',
'sırım',
'simav',
'topik',
'yapak',
'ihraz',
'sebze',
'musap',
'şaibe',
'emici',
'yatay',
'forte',
'iftar',
'düzeç',
'ivmek',
'kirve',
'hecin',
'suluk',
'baton',
'enlem',
'rabıt',
'bakir',
'gülle',
'habip',
'mebde',
'domur',
'yortu',
'kenar',
'afişe',
'örtüş',
'gaşiy',
'mezat',
'savaş',
'göyme',
'fokus',
'kıraç',
'surat',
'ünsüz',
'gusto',
'erime',
'yığış',
'kayır',
'açmaz',
'köhne',
'siyak',
'hatır',
'kazaz',
'elyaf',
'isnat',
'türüm',
'lütuf',
'ikona',
'teizm',
'avlak',
'eksik',
'şınav',
'lotus',
'akbaş',
'sürfe',
'koşut',
'lıkır',
'madik',
'dekor',
'emraz',
'şeran',
'oynaş',
'çalkı',
'kuron',
'bizon',
'yonca',
'sakın',
'doğru',
'potas',
'filiz',
'tüylü',
'unluk',
'trafo',
'kanıt',
'seren',
'haşir',
'kumuç',
'muzır',
'telif',
'ifsat',
'geyşa',
'viski',
'sığır',
'asklı',
'cihat',
'bilye',
'perde',
'fıkra',
'esans',
'yılık',
'tiner',
'yasin',
'bölüş',
'hürle',
'çekem',
'voyvo',
'yuvgu',
'anons',
'olmaz',
'alize',
'güpür',
'ferih',
'cülus',
'dalan',
'özenç',
'arter',
'çavma',
'temas',
'dışkı',
'hodan',
'mafiş',
'şömiz',
'beyit',
'hülya',
'dikey',
'açgöz',
'talak',
'nafia',
'hüner',
'süsme',
'artış',
'tezek',
'tazip',
'yayma',
'oniks',
'değin',
'gabya',
'eçhel',
'düşün',
'bravo',
'serim',
'polüm',
'macun',
'ığdır',
'bıyık',
'küspe',
'nefir',
'hisar',
'gübür',
'çıkra',
'haber',
'keşiş',
'mesaj',
'sikke',
'silgi',
'kağnı',
'nakil',
'çıngı',
'butlu',
'geoit',
'ibraz',
'parça',
'yüklü',
'bölme',
'misak',
'palas',
'kılış',
'largo',
'nöbet',
'karay',
'fesat',
'ödeme',
'cüsse',
'dürüm',
'gizem',
'organ',
'hazım',
'korno',
'misil',
'pilot',
'devce',
'çilli',
'sümük',
'samut',
'diniş',
'tepme',
'çengi',
'joker',
'öbürü',
'kuram',
'bünye',
'ladin',
'çöplü',
'guatr',
'tarak',
'yaraş',
'hayfa',
'külek',
'geçme',
'kahpe',
'varit',
'acemi',
'çağrı',
'kelle',
'hacim',
'ılgın',
'mezra',
'nebat',
'forsa',
'tarım',
'yunus',
'ezine',
'rötuş',
'serak',
'başka',
'sabık',
'murat',
'zarar',
'yollu',
'fifre',
'peştu',
'levye',
'yosun',
'söğüt',
'bodur',
'çatkı',
'bölük',
'venüs',
'efrat',
'koyun',
'iskan',
'sancı',
'eneze',
'hatif',
'bohça',
'gürcü',
'sökme',
'ussal',
'kızık',
'gnays',
'lakin',
'köken',
'telis',
'mesen',
'tavır',
'hozan',
'soyum',
'basar',
'icmal',
'arama',
'sorun',
'iddia',
'motel',
'budun',
'nodul',
'nüans',
'saklı',
'lezar',
'karha',
'helke',
'çevri',
'emsal',
'çorum',
'tonaj',
'aslan',
'rahne',
'üryan',
'fişek',
'kılıç',
'sonlu',
'kavuk',
'fünye',
'malul',
'üzünç',
'evham',
'uyluk',
'deyiş',
'yükçü',
'uçmak',
'kıpma',
'sezon',
'rüsva',
'fitil',
'mikap',
'arazi',
'çökme',
'kavim',
'zamir',
'selek',
'silik',
'doğaç',
'kirpi',
'çizge',
'duacı',
'şahit',
'gövde',
'abide',
'tonya',
'getto',
'kavmi',
'tüplü',
'dikme',
'kıpti',
'kuzin',
'raunt',
'model',
'yıkış',
'lığlı',
'bütün',
'talih',
'elmek',
'çürük',
'sonra',
'görme',
'duyma',
'kemal',
'hippi',
'ılgaz',
'yeğni',
'ışkın',
'hüsün',
'denli',
'ağlak',
'hande',
'şafii',
'tatma',
'peyke',
'tezce',
'astat',
'dalga',
'herze',
'hayıf',
'hafit',
'sakil',
'hadim',
'enfes',
'siyek',
'milim',
'sakit',
'hızma',
'çıban',
'neşir',
'lider',
'zağlı',
'kuruş',
'şapka',
'batkı',
'mutki',
'kesen',
'müziç',
'hadde',
'rejim',
'rubai',
'fiber',
'yorga',
'geçer',
'leziz',
'kakım',
'tröst',
'hayda',
'geren',
'rapor',
'memul',
'fekül',
'solak',
'katil',
'suoku',
'yenli',
'hanut',
'naşir',
'halik',
'ipeka',
'ilave',
'selvi',
'reviş',
'levha',
'özerk',
'hatıl',
'hasım',
'nisai',
'kamga',
'röfle',
'rotil',
'dayak',
'urgan',
'esmer',
'metbu',
'tüfek',
'çakıl',
'avane',
'kakaç',
'susta',
'döveç',
'unvan',
'ferdi',
'aplik',
'sorgu',
'hurra',
'marke',
'dümen',
'conta',
'duluk',
'göcen',
'perki',
'ılgar',
'yazık',
'çevik',
'vuruk',
'larva',
'matiz',
'mariz',
'orası',
'plati',
'senet',
'komün',
'siyme',
'çınar',
'sarım',
'dolma',
'çökek',
'rayiç',
'dekar',
'değim',
'nükul',
'dural',
'pişik',
'kuytu',
'puslu',
'ürgüp',
'ırgat',
'islav',
'teles',
'pişti',
'silme',
'dizin',
'sezme',
'şişme',
'cizre',
'rende',
'sabır',
'sokak',
'kablo',
'melal',
'üstlü',
'zımba',
'işkal',
'hürya',
'çiroz',
'taret',
'nodül',
'siper',
'insaf',
'laski',
'cemre',
'sınai',
'manti',
'küplü',
'turaç',
'boruk',
'polen',
'simit',
'sivas',
'pasta',
'kavga',
'yörük',
'katip',
'fason',
'ebedi',
'tabip',
'penye',
'humar',
'yumma',
'satış',
'ikrar',
'eleme',
'ötücü',
'lügat',
'kolpo',
'döşem',
'kamçı',
'nasıl',
'telli',
'asılı',
'fikri',
'frape',
'gıcık',
'melez',
'kokuş',
'livar',
'sülün',
'gömüt',
'aside',
'öncel',
'vezin',
'civan',
'cukka',
'kroki',
'yirmi',
'acıma',
'oksit',
'düver',
'çıkan',
'dönek',
'aşıcı',
'yudum',
'çöven',
'tıkız',
'hanek',
'gölge',
'tasma',
'sarak',
'işlik',
'itila',
'kepek',
'hanım',
'koçma',
'sepet',
'fonem',
'taraf',
'sücut',
'sükut',
'taşra',
'kural',
'iğlik',
'onmak',
'göçüm',
'yegan',
'ipsiz',
'büküm',
'yeşim',
'esrar',
'aroma',
'tünek',
'nüfus',
'yasal',
'furya',
'mebni',
'hükmi',
'simge',
'parke',
'duvar',
'gudde',
'uysal',
'kabir',
'şahne',
'eyyam',
'yorma',
'leydi',
'kaçak',
'hepsi',
'incik',
'kıyım',
'menfa',
'halaç',
'akkor',
'doğan',
'falya',
'yanış',
'özden',
'kıllı',
'roket',
'penis',
'lehim',
'faslı',
'kaçlı',
'kovuk',
'vargı',
'jilet',
'tuman',
'kağan',
'kemer',
'gebre',
'popçu',
'dilim',
'sabun',
'şaşma',
'örmek',
'samur',
'dökük',
'denyo',
'izmir',
'okluk',
'sadik',
'aygır',
'dinar',
'ağılı',
'ketum',
'ahşap',
'coşku',
'sedye',
'sözlü',
'sükse',
'seçim',
'seviş',
'dağar',
'sönüm',
'grena',
'kulis',
'sürme',
'haycı',
'çemen',
'ıtlak',
'kösçü',
'sulta',
'telaş',
'ölçüş',
'düşkü',
'çamaş',
'gövek',
'rezil',
'pasaj',
'nadan',
'hizip',
'galon',
'kalak',
'şorca',
'timüs',
'eküri',
'palan',
'vedia',
'permi',
'katar',
'çaltı',
'tatar',
'zorba',
'hüküm',
'rugan',
'fazıl',
'ricat',
'azlık',
'evcek',
'çömme',
'kadar',
'olein',
'nemse',
'çıkış',
'döneç',
'tevek',
'kahta',
'tadım',
'kubat',
'kayık',
'habis',
'pişek',
'binek',
'kısma',
'ozmoz',
'firma',
'lizol',
'borda',
'hariç',
'ibare',
'bilet',
'nokta',
'nakit',
'güfte',
'çoban',
'velut',
'sakat',
'bronş',
'üzmek',
'kopuş',
'kilim',
'fütur',
'erika',
'yumru',
'susku',
'haham',
'devim',
'süzme',
'salim',
'şarap',
'namus',
'piyan',
'sever',
'sütçü',
'yapay',
'saksı',
'zeyil',
'cacık',
'mıhlı',
'küsur',
'gonca',
'kilis',
'vagon',
'sapak',
'çolpa',
'batış',
'kabza',
'erkin',
'kredi',
'oflaz',
'pınar',
'mübah',
'boyun',
'saçak',
'ilkah',
'velet',
'tabir',
'sallı',
'suret',
'potuk',
'fırın',
'bakan',
'haşat',
'çatma',
'soluş',
'peşin',
'cezir',
'borat',
'gotik',
'varak',
'verem',
'sarpa',
'afaki',
'kesiş',
'nakli',
'topaz',
'çekül',
'dölek',
'ibret',
'düzem',
'sünni',
'vıcık',
'natır',
'sığın',
'giyim',
'refik',
'palet',
'habeş',
'çepel',
'üçgül',
'dival',
'keşke',
'hangi',
'çatış',
'tepsi',
'dolgu',
'sıtma',
'tabak',
'kömeç',
'nezif',
'meyil',
'işlem',
'söküş',
'irsal',
'dizme',
'kırcı',
'tatlı',
'fehva',
'yeleç',
'itina',
'jokey',
'deney',
'cephe',
'cevir',
'astım',
'yalım',
'neşet',
'basış',
'kemik',
'edinç',
'dikel',
'özgün',
'savan',
'körük',
'ferik',
'maada',
'ezber',
'nesih',
'atari',
'manav',
'çerge',
'yülgü',
'ayran',
'güney',
'püren',
'tenor',
'toklu',
'tavuk',
'zebra',
'ikdam',
'dizey',
'liret',
'aksak',
'harım',
'fahri',
'kamış',
'çarpı',
'avize',
'çiğli',
'marki',
'sevda',
'otacı',
'nakşi',
'vahşi',
'salma',
'motto',
'zağcı',
'sepya',
'kavat',
'nadas',
'şakul',
'ismet',
'vahim',
'flori',
'mıgri',
'günce',
'köycü',
'duyar',
'hasbi',
'semai',
'fauna',
'keriz',
'ispir',
'teşyi',
'tınma',
'kehle',
'gitme',
'arsız',
'subay',
'takva',
'nacak',
'saika',
'böbür',
'dikim',
'fehim',
'ekser',
'acaba',
'kahin',
'geyve',
'möble',
'yahni',
'dalız',
'rahle',
'girme',
'samsa',
'şahin',
'inzal',
'kasık',
'törpü',
'galiz',
'sedef',
'otist',
'çırpı',
'kudüm',
'çeper',
'hafik',
'şekva',
'yetik',
'sınıf',
'kokoz',
'silis',
'özdeş',
'nehir',
'mapus',
'telin',
'esnaf',
'tartı',
'flora',
'evvel',
'üstün',
'talep',
'terim',
'türel',
'ihvan',
'almak',
'radar',
'ipucu',
'seyis',
'natür',
'dubar',
'göçüş',
'süfli',
'yatak',
'flöre',
'hanak',
'iteği',
'süngü',
'halay',
'devre',
'hayta',
'ampir',
'işmar',
'meret',
'nüzul',
'lepra',
'tesri',
'kakış',
'kayme',
'tenis',
'mevki',
'cesim',
'çapar',
'hoşaf',
'özeni',
'güçlü',
'ızrar',
'krank',
'foroz',
'kımıl',
'havai',
'kerte',
'şarpi',
'hakir',
'sille',
'şamar',
'kadit',
'kadın',
'gamba',
'kudas',
'slayt',
'kolik',
'deniz',
'takke',
'bağcı',
'şekil',
'üzere',
'sınık',
'hulus',
'softa',
'tabya',
'haset',
'hasır',
'uskur',
'pomak',
'köklü',
'sakak',
'nazir',
'okapi',
'kraça',
'sapma',
'övünç',
'kamus',
'güzey',
'kinli',
'izmit',
'çemçe',
'maral',
'cüruf',
'medet',
'tanin',
'ikrah',
'topal',
'kurna',
'kebap',
'hurma',
'epsem',
'ecdat',
'giyit',
'çakal',
'huruç',
'buket',
'allık',
'serin',
'munis',
'özgül',
'mozak',
'vasıf',
'jakar',
'fuhuş',
'yalpı',
'şemsi',
'bombe',
'hadis',
'gütme',
'galat',
'tanen',
'bavul',
'eyvan',
'ölçüm',
'ilhak',
'eşsiz',
'süper',
'hitit',
'kelli',
'masif',
'sekte',
'kabil',
'sözcü',
'hicap',
'çanlı',
'tırıs',
'cıbıl',
'kurum',
'odacı',
'tekne',
'vites',
'rical',
'kıyın',
'çiziş',
'pulsu',
'ırama',
'demli',
'lopur',
'kovma',
'izzet',
'karma',
'yatsı',
'sakin',
'yomra',
'dişçi',
'mecus',
'secde',
'mamut',
'evrat',
'nizip',
'çekiş',
'kısım',
'kulaç',
'düzgü',
'kıskı',
'koçan',
'ejder',
'makas',
'tuzak',
'kitre',
'nanay',
'boğaz',
'erken',
'sanma',
'koyuş',
'faset',
'ahkam',
'kolsu',
'farad',
'şahsi',
'nifak',
'eğmür',
'makak',
'sakar',
'ragbi',
'çökük',
'okuma',
'heybe',
'kılık',
'pasçı',
'şamil',
'demin',
'azami',
'valör',
'gıdım',
'züppe',
'cebri',
'vusul',
'ellik',
'şifre',
'orfoz',
'yolak',
'seyir',
'fince',
'değer',
'telve',
'kusma',
'sahil',
'hamsi',
'derin',
'kubur',
'longa',
'tavaf',
'patal',
'kısır',
'eleji',
'vehim',
'türki',
'geniş',
'cuşiş',
'mamur',
'güzel',
'radon',
'tahıl',
'hemen',
'melon',
'fatsa',
'asmak',
'delme',
'tiriz',
'şeref',
'kalem',
'konum',
'artım',
'tenya',
'şoson',
'kamer',
'etene',
'nalın',
'yılgı',
'ivedi',
'liyan',
'yitik',
'engin',
'pedal',
'tafta',
'idadi',
'küllü',
'ihzar',
'kuşku',
'siniş',
'teber',
'sınır',
'kuvöz',
'şayia',
'görgü',
'minör',
'muhal',
'mülki',
'detay',
'parya',
'imame',
'bloke',
'baldo',
'topla',
'puluç',
'odsuz',
'uçsuz',
'donlu',
'düşüş',
'düğün',
'hayız',
'boran',
'hasıl',
'suyuk',
'çırak',
'fakih',
'yazar',
'halel',
'akran',
'payan',
'bilir',
'döper',
'inönü',
'ballı',
'barok',
'antre',
'sayaç',
'fidan',
'malum',
'kefir',
'ironi',
'erzak',
'viran',
'idrar',
'hanya',
'vasıl',
'tören',
'gamet',
'jüpon',
'kahya',
'muhip',
'kakma',
'nazar',
'yazın',
'gıpta',
'zanlı',
'ürüme',
'meriç',
'tepiş',
'sıvık',
'firar',
'dölüt',
'tempo',
'ongun',
'pekçe',
'talaş',
'rumca',
'pahal',
'cinai',
'hilye',
'betik',
'kutlu',
'nükte',
'karlı',
'bakış',
'rolcü',
'çekek',
'maddi',
'kerde',
'çözük',
'kölük',
'uşşak',
'sıygı',
'öneri',
'gotça',
'helme',
'güdek',
'modül',
'küşat',
'makul',
'tümel',
'topur',
'şatır',
'halka',
'mirat',
'seçme',
'yüzlü',
'defin',
'yaran',
'subra',
'harın',
'frisa',
'asist',
'dönem',
'çözgü',
'ezgin',
'üsküf',
'gaita',
'süzüş',
'kondu',
'diril',
'yatış',
'mevzu',
'teori',
'fişli',
'sahne',
'peren',
'enöte',
'hitap',
'cinci',
'gıcır',
'tacir',
'kadim',
'önlem',
'kapış',
'deist',
'teyit',
'celep',
'imalı',
'tabii',
'arızi',
'yontu',
'mazak',
'hüzün',
'dünit',
'hisse',
'kapik',
'yunan',
'dingo',
'yitiş',
'oynak',
'neyçe',
'lanet',
'özlük',
'zihni',
'minik',
'böyle',
'havut',
'evsaf',
'yolma',
'övücü',
'muare',
'uğraş',
'kıyam',
'leyli',
'dünkü',
'donma',
'piton',
'döger',
'butik',
'küskü',
'kızan',
'sifon',
'medüz',
'ramak',
'kaygı',
'değiş',
'limon',
'miçel',
'soğuk',
'tikel',
'gürün',
'kalıt',
'elifi',
'husul',
'salaş',
'volan',
'mühim',
'maske',
'sehim',
'düdük',
'buton',
'hamel',
'mezar',
'sırat',
'rüesa',
'batın',
'mucip',
'oraya',
'tazim',
'soyut',
'ideal',
'dirim',
'çopur',
'pisik',
'içmek',
'mahir',
'panel',
'taziz',
'şimdi',
'damak',
'ovmaç',
'iklim',
'fetha',
'emcik',
'piyon',
'diren',
'deste',
'lapon',
'canip',
'sekiş',
'krema',
'halas',
'maşuk',
'prens',
'yaman',
'potur',
'muştu',
'yahey',
'duble',
'duruk',
'hırka',
'yavru',
'ehven',
'zurna',
'süğme',
'ürküş',
'mumya',
'olmak',
'atlas',
'espas',
'kerim',
'sokma',
'nesir',
'kayıp',
'keşik',
'posof',
'telem',
'avşar',
'çizer',
'mesel',
'bordo',
'iradi',
'savat',
'toner',
'sicim',
'cinas',
'muris',
'tahin',
'istem',
'fetiş',
'idari',
'vurma',
'diliş',
'durgu',
'akşam',
'lokum',
'pizza',
'çıktı',
'sütre',
'alkol',
'aynen',
'takas',
'ithaf',
'dudak',
'seçal',
'ilhan',
'kılgı',
'salto',
'koral',
'kelam',
'güveç',
'ölçün',
'pısma',
'malen',
'gerçi',
'votka',
'sürüm',
'puvar',
'cidar',
'çorap',
'zulüm',
'cilve',
'beril',
'somut',
'metil',
'naçar',
'viyol',
'marka',
'sözel',
'çarık',
'begüm',
'doruk',
'etmek',
'liman',
'astar',
'anket',
'rodaj',
'patak',
'cihaz',
'yürük',
'azeri',
'müdür',
'boyoz',
'paten',
'pamuk',
'ayyaş',
'ölçek',
'mevla',
'beton',
'eşlik',
'ender',
'elvan',
'halis',
'yaren',
'ölçme',
'humma',
'marya',
'izafi',
'elzem',
'ashap',
'sorma',
'esvap',
'safha',
'laden',
'taşak',
'ezeli',
'beyin',
'çömçe',
'zigot',
'fahiş',
'cunta',
'redif',
'kirli',
'izlem',
'lonca',
'miyaz',
'çorak',
'şoset',
'memat',
'gabin',
'çakra',
'demet',
'keski',
'dince',
'hadım',
'kıyış',
'odalı',
'böğür',
'kaime',
'rijit',
'sevgi',
'sülüs',
'tüzük',
'gücük',
'prova',
'kasis',
'plaka',
'hatmi',
'görev',
'parsa',
'pazen',
'melul',
'süyek',
'yamak',
'beyaz',
'nazım',
'irmik',
'gazlı',
'dizge',
'kriko',
'terli',
'çiriş',
'eğmeç',
'ahlak',
'bemol',
'deşme',
'kolay',
'maval',
'demir',
'nihan',
'nazal',
'uzlet',
'poker',
'figan',
'karst',
'alkan',
'reaya',
'zaman',
'azman',
'farba',
'sazlı',
'sümer',
'cehil',
'dikta',
'mimli',
'mülga',
'göçme',
'ipham',
'keder',
'mecra',
'verme',
'necat',
'tedai',
'hoşça',
'hatim',
'ütmek',
'suçlu',
'ensar',
'örcin',
'naçiz',
'sayım',
'tutak',
'ciddi',
'kürit',
'somun',
'yahut',
'kekeç',
'irkme',
'yaşlı',
'tortu',
'yahşi',
'üşüme',
'lorta',
'kopil',
'doluş',
'tanım',
'sümen',
'ekran',
'lüpçü',
'kanma',
'panda',
'konur',
'keyif',
'baraj',
'sökük',
'mirza',
'bilim',
'belge',
'denge',
'uymak',
'raflı',
'çomar',
'nihai',
'menşe',
'goril',
'majör',
'paraf',
'tipik',
'kuzen',
'tokat',
'kovcu',
'pinel',
'yukaç',
'kıyak',
'tınaz',
'mabat',
'ezmek',
'sahur',
'mesut',
'taşçı',
'tamim',
'kayma',
'dikse',
'uğrun',
'kamet',
'sisli',
'ergen',
'selis',
'tasar',
'lehçe',
'muşta',
'budak',
'yapık',
'mezzo',
'rebap',
'piliç',
'ısrar',
'akait',
'kokma',
'hedik',
'şişko',
'sanki',
'ihmal',
'lümen',
'molla',
'damar',
'zahir',
'şilep',
'kevel',
'halfa',
'paçoz',
'şilte',
'israf',
'çepni',
'harlı',
'keleş',
'aşırı',
'ışıma',
'daire',
'susma',
'kazıl',
'hızlı',
'kıyma',
'cunda',
'bronz',
'fanti',
'vücut',
'sefer',
'örgüt',
'çoğun',
'maçka',
'polar',
'encam',
'nahiv',
'ölçüt',
'mimar',
'siroz',
'mango',
'faril',
'yulaf',
'sarat',
'özlem',
'ılıca',
'irşat',
'kumcu',
'umman',
'turne',
'imlek',
'trata',
'kusur',
'etfal',
'emmeç',
'koğuş',
'miyom',
'terme',
'havuz',
'sövgü',
'hamak',
'altın',
'cenup',
'demre',
'monat',
'süluk',
'reçel',
'mahur',
'çifte',
'omlet',
'mucit',
'çoğul',
'firez',
'terfi',
'kağıt',
'işgal',
'hünsa',
'piyes',
'metot',
'tirsi',
'girim',
'mitoz',
'dünya',
'bıçkı',
'yarka',
'süslü',
'kavis',
'iptal',
'rasyo',
'damat',
'tırıl',
'ortez',
'küsme',
'nazlı',
'eneme',
'vuzuh',
'musır',
'aygıt',
'egale',
'temin',
'çavlı',
'coşma',
'adres',
'alkış',
'şedit',
'etken',
'malya',
'devam',
'katlı',
'nedim',
'mösyö',
'salah',
'parka',
'cılız',
'şopar',
'erbin',
'çıkık',
'metre',
'kuşak',
'üçgen',
'üsera',
'vezne',
'horon',
'kavil',
'yapım',
'izlev',
'iblağ',
'deyim',
'onama',
'düşeş',
'lazca',
'arkıt',
'gölet',
'salpa',
'olgun',
'verim',
'aleni',
'kılıf',
'duman',
'tulum',
'lizöz',
'sükun',
'küvet',
'balet',
'mühre',
'sayrı',
'biçim',
'teres',
'alman',
'künde',
'topak',
'biniş',
'üdeba',
'betim',
'haciz',
'feyiz',
'tavcı',
'sebep',
'çıkma',
'sinir',
'yekun',
'börek',
'ebeli',
'hodri',
'rüsup',
'boyar',
'cedit',
'cumba',
'maşer',
'nasip',
'mucur',
'cibre',
'kıran',
'biblo',
'sabit',
'koşaç',
'rozet',
'yakım',
'limit',
'mezür',
'selen',
'kısık',
'sutaş',
'meşbu',
'şoven',
'yapma',
'istim',
'muson',
'dibek',
'çerağ',
'fakir',
'giysi',
'banko',
'alkım',
'pişme',
'espri',
'miyop',
'geriş',
'haçlı',
'ortay',
'kırca',
'mürur',
'niğde',
'ihtar',
'disko',
'nohut',
'grogi',
'sanlı',
'terzi',
'yakut',
'parpa',
'talan',
'dürzi',
'kekik',
'opera',
'tıfıl',
'kelek',
'cüret',
'ruhlu',
'gezme',
'grizu',
'cübbe',
'mevut',
'gidiş',
'şükür',
'bakma',
'ermin',
'mucir',
'gafur',
'kalık',
'batma',
'milas',
'felek',
'ringa',
'abaza',
'mufla',
'ahlat',
'iflas',
'tutuk',
'buyma',
'yükün',
'tozan',
'dimağ',
'varoş',
'islam',
'sergi',
'izhar',
'bastı',
'basık',
'yemci',
'sındı',
'asker',
'kilit',
'baskı',
'duygu',
'nipel',
'rakor',
'mıcır',
'çasar',
'mukni',
'içlem',
'kıtal',
'indis',
'tolga',
'yazım',
'lobut',
'dönüş',
'gayya',
'dikçe',
'tıkır',
'tonik',
'hikem',
'konma',
'eksen',
'yatçı',
'soylu',
'dürme',
'daima',
'çalık',
'mahfe',
'ülser',
'aslen',
'sütsü',
'yunma',
'istop',
'talaz',
'löpür',
'zafer',
'hakim',
'basım',
'beyzi',
'kazım',
'sahre',
'tedip',
'nöron',
'kerih',
'yegah',
'rufai',
'bilek',
'rafya',
'layık',
'fener',
'bölge',
'triko',
'delik',
'tütün',
'beşik',
'göbel',
'aşure',
'gözde',
'refüj',
'yargı',
'kimse',
'ahbap',
'nasır',
'çekme',
'çekim',
'işteş',
'fenni',
'oğlak',
'şafak',
'hafif',
'havas',
'aşevi',
'ikraz',
'baran',
'nezle',
'sahip',
'evcil',
'belli',
'boğum',
'ceket',
'bahar',
'hödük',
'mizah',
'dilme',
'vakur',
'dogma',
'nafta',
'hidra',
'emlik',
'isale',
'servi',
'roman',
'bayır',
'yağız',
'gediz',
'kofti',
'ifrit',
'beden',
'haşiş',
'cebel',
'optik',
'albüm',
'fasih',
'şaman',
'bence',
'kefen',
'sonar',
'mukim',
'erkek',
'lüzum',
'kasko',
'taviz',
'üzeri',
'kulun',
'yanıt',
'ovalı',
'kütük',
'eğmek',
'yanal',
'türev',
'bağlı',
'şetim',
'derya',
'papaz',
'sunta',
'depar',
'huylu',
'kaval',
'fiyat',
'sağır',
'madam',
'geviş',
'sumak',
'illet',
'çinko',
'itaat',
'sıfat',
'geçen',
'jeton',
'güleç',
'künye',
'kanat',
'tanış',
'bedel',
'evsiz',
'ukala',
'fedai',
'celbe',
'tafra',
'batak',
'mermi',
'karga',
'güreş',
'plase',
'sazan',
'çatal',
'kasap',
'konya',
'ortam',
'obruk',
'orada',
'gurur',
'cümle',
'lagün',
'manat',
'süzek',
'basak',
'düşey',
'yoğun',
'rodeo',
'kenya',
'uyuma',
'darbe',
'ünlem',
'yayık',
'tunik',
'yağış',
'çarşı',
'hışım',
'tümce',
'sevap',
'rumuz',
'civar',
'esnek',
'susam',
'çakış',
'gülüş',
'yoksa',
'tenha',
'ihale',
'yerli',
'oktav',
'miras',
'rutin',
'oymak',
'ifraz',
'proje',
'soyma',
'yavaş',
'seçki',
'bekar',
'kiler',
'gübre',
'vatan',
'aptal',
'pençe',
'helik',
'imece',
'kroşe',
'geçit',
'flama',
'kürsü',
'satıh',
'yağma',
'seksi',
'topçu',
'sütun',
'salam',
'lafzi',
'çatık',
'akrep',
'tütme',
'kıssa',
'teras',
'bengi',
'hicaz',
'yenge',
'rampa',
'gitar',
'ütücü',
'camcı',
'dürzü',
'ihlas',
'fazla',
'besin',
'rakam',
'bukle',
'final',
'akçıl',
'şerit',
'lazım',
'tabla',
'dikiş',
'dikit',
'şirin',
'koruk',
'katot',
'tutar',
'eylül',
'ortaç',
'kabul',
'dikte',
'çıkıt',
'dalak',
'aydın',
'yelek',
'yitim',
'heves',
'mahya',
'panik',
'cenah',
'gurme',
'meyve',
'havya',
'takma',
'koçak',
'tarik',
'fırka',
'yıkık',
'teist',
'gökçe',
'poşet',
'mason',
'vanlı',
'zekat',
'ebleh',
'arpej',
'vergi',
'imdat',
'sehpa',
'dalma',
'çetin',
'nefer',
'çekiç',
'rusça',
'humus',
'aşina',
'monte',
'maviş',
'kaçar',
'gergi',
'fakat',
'karın',
'köşek',
'lades',
'ahali',
'imren',
'mecaz',
'tutum',
'sirke',
'sırça',
'hızar',
'vakıa',
'varan',
'göğüs',
'mazur',
'duyum',
'kuple',
'yeter',
'yalan',
'ülfet',
'çıtak',
'tirat',
'sudan',
'guguk',
'yaban',
'dilek',
'hücum',
'köprü',
'davet',
'nizam',
'yüküm',
'borsa',
'otsul',
'çanak',
'macar',
'kopuz',
'yarış',
'revak',
'ülger',
'kaide',
'yular',
'tüzel',
'mezun',
'donuk',
'üslup',
'fetih',
'fular',
'falso',
'hissi',
'bohem',
]
