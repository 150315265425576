export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['İyi iş!', 'Mükemmel', 'Aferin!']
export const GAME_COPIED_MESSAGE = 'Oyun panoya kopyalandı'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Yeterli harf yok'
export const WORD_NOT_FOUND_MESSAGE = 'Kelime bulunamadı'
export const HARD_MODE_ALERT_MESSAGE =
  'Zor Mod yalnızca başlangıçta etkinleştirilebilir!'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `kelime oldu ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Kullanılmalı ${guess} pozisyonda ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Tahmin içermelidir ${letter}`
export const ENTER_TEXT = 'Girmek'
export const DELETE_TEXT = 'Silmek'
export const STATISTICS_TITLE = 'İstatistik'
export const GUESS_DISTRIBUTION_TEXT = 'Tahmin Dağılımı'
export const NEW_WORD_TEXT = 'Yeni kelime'
export const SHARE_TEXT = 'Paylaş'
export const TOTAL_TRIES_TEXT = 'Toplam deneme'
export const SUCCESS_RATE_TEXT = 'Başarı oranı'
export const CURRENT_STREAK_TEXT = 'Mevcut Seri'
export const BEST_STREAK_TEXT = 'En iyi galibiyet'
export const GAME_LINK = 'Daha iyisini yapabileceğini mi düşünüyorsun? \n https://test.joedle.com'