import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Nasıl oynanır" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        6 denemede kelimeyi tahmin et. Her tahminden sonra, karoların rengi
        tahmininizin kelimeye ne kadar yakın olduğunu göstermek için değiştirin.
      </p>

      <div className="flex justify-center mb-1 mt-4">
      <Cell
          isRevealing={true}
          isCompleted={true}
          value="F"
          status="correct"
        />
        <Cell value="U" />
        <Cell value="L" />
        <Cell value="A" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        F harfi kelimede ve doğru yerde.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="P" />

               <Cell
          isRevealing={true}
          isCompleted={true}
          value="İ"
          status="present"
        />

        <Cell value="Z" />
        <Cell value="Z" />
        <Cell value="A" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        İ harfi kelimede ama yanlış yerde.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="Ç" />
        <Cell value="İ" />
        <Cell value="Z" />
        <Cell isRevealing={true} isCompleted={true} value="E" status="absent" />
        <Cell value="R" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        E harfi kelimenin hiçbir yerinde yok.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
	  Türkçe Kelimeler,{' '}
	  <a
      href="https://ko-fi.com/joedel263"
      className="underline font-bold"
	  target="_BLANK" rel="noreferrer"
      >
	  Joe 
        </a>{' '}	  
	  tarafından yaratılmıştır ve popüler kelime tahmin oyununun açık kaynaklı bir sürümü olan{' '} 
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
	  target="_BLANK" rel="noreferrer"		  
        >
          Reactle
        </a> 
	  'ın çevrilmiş bir versiyonudur.
      </p>
    </BaseModal>
  )
}
